<template>
  <div class="takeClothesIndexView content-index">
    <div class="topView">
      <div class="tView">
        <el-page-header title="上一步" @back="$router.go(-1)" content="取衣列表"></el-page-header>
      </div>
    </div>
    <div class="takeClothes">
      <div class="tableCtn">
        <el-table
            :data="dataList"
            stripe
            style="width: 100%" v-loading="showQueryLoading">
          <el-table-column type="index" label="序号" align="center">
            <template slot-scope="scope">
              <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="orderNumber" label="订单号" width="120" align="center"/>
          <el-table-column prop="userName" label="姓名" align="center"/>
          <el-table-column prop="userPhone" label="电话" align="center">
            <template slot-scope="scope">
            <span v-hidephone> {{ scope.row.userPhone }}</span>
          </template>
          </el-table-column>
          <el-table-column prop="actuallyPaidPrice" label="订单金额" align="center"/>
          <el-table-column prop="orderStatus" label="订单状态" align="center"/>
          <el-table-column prop="payStatus" label="支付状态" align="center"/>
          <el-table-column prop="orderTime" label="下单时间" width="150" align="center"/>
          <el-table-column prop="clothesNum" label="订单件数(件)" align="center"/>
          <el-table-column prop="pickClothesCount" label="可取衣物(件)" align="center"/>
          <el-table-column
              fixed="right"
              label="操作"
              width="100"
              align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="handleOrderDetail(scope.row.orderNumber)">取衣</el-button>
            </template>
          </el-table-column>
        </el-table>


        <div class="pageBox">
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <transition name="userDetails">
      <clothes-detail-view style="z-index: 1999;" :visible="dialogTableVisible" ref="clothesDetail"
                           @closeDel="detailBack"></clothes-detail-view>
    </transition>
  </div>
</template>

<script>
import {takeClothes} from '@/api/clothes/order'
import ClothesDetailView from "@/view/takeClothes/components/clothesDetailView";

export default {
  name: "takeClothes",
  components: {
    ClothesDetailView
  },
  data() {
    return {
      dialogTableVisible: false,
      showQueryLoading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总数量
      moreList: [],
      dataList: [],
      orderNumber: ''
    }
  },
  created() {
    this.handleQuery();
  },
  methods: {
    // 处理查询
    handleQuery() {

      let keyword = this.$route.query.keyword;
      this.showQueryLoading = true
      const params = new URLSearchParams()
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      params.append('phone', keyword)
      takeClothes(params).then(response => {
        this.showQueryLoading = false
        this.dataList = response.data.data.orderList;
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleQuery()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.handleQuery()
    },
    //订单详情
    handleOrderDetail(orderNumber) {
      this.$refs.clothesDetail.getClothesOrderDetails(orderNumber);
      this.dialogTableVisible = true
    },
    detailBack() {
      this.dialogTableVisible = false;
      this.handleQuery();
    },
    handleClose() {
      this.dialogTableVisible = false
      this.$refs.clothesDetail.clothesNumber = '';
    },
  }
}
</script>

<style scoped lang="scss">
ul, li {
  padding: 0;
  margin: 0;
}

.takeClothes {
  margin-top: 20px;
  padding: 0 20px;

  .notYetNum {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 480px;
  }


  .pageBox {
    text-align: right;
    margin: 20px 0;
  }
}

.takeClothesIndexView {
  position: relative;
  overflow: auto;
  font-family: PingFangSC-Regular;

  > .topView {
    padding: 15px 20px;
    width: 97%;

    > .tView {
      color: #333;
      text-align: left;
     font-size: 24px;
    }
  }

  > .searchView.show {
    transform: translateY(-90px);
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

//动画过程
.userDetails-enter {
  animation: userDetails-dialog-fade-in .4s ease;
}

.userDetails-enter-active {
  animation: userDetails-dialog-fade-in .4s ease
}

//离开
.userDetails-leave {
  animation: userDetails-dialog-fade-out .4s ease;
}

.userDetails-leave-active {
  animation: userDetails-dialog-fade-out .4s ease;
}

@keyframes userDetails-dialog-fade-in {
  0% {
    transform: translateX(100%);
    // opacity: 0;
  }
  100% {
    transform: translateX(0);
    // opacity: 1;
  }
}

@keyframes userDetails-dialog-fade-out {
  0% {
    transform: translateX(0);
    // opacity: 1;
  }
  100% {
    transform: translateX(100%);
    // opacity: 0;
  }
}
</style>
