<template>
  <div class="clothesDetailView" v-if="visible">
    <div class="topView">
      <div class="backView">
        <el-button
          class="btn-back"
          icon="el-icon-arrow-left"
          @click="closeVisible"
        >
          返回
        </el-button>
      </div>
      <div class="contentView">
        <div class="tView">订单详情</div>
        <div>
          <!-- <el-input v-model="clothesNumber" ref="clothesRefs" @keyup.enter.native="clothesPick(orderDetailOption)" placeholder="请输入衣物号" style="width: 200px; margin-right: 10px;"></el-input> -->
          <!-- <el-button type="primary" @click="clothesPick(orderDetailOption)">取衣</el-button> -->
          <el-button
            type="warning"
            style="background: #e6a23c;"
            v-if="
              orderDetailOption.payStatus === '未支付' &&
                (orderDetailOption.orderStatus !== '已取消' ||
                  orderDetailOption.orderStatus !== '已退款')
            "
            @click="handlePayModal"
          >
            去支付
          </el-button>
          <el-button
            type="warning"
            style="background: #e6a23c;"
            v-if="
              orderDetailOption.payStatus === '未支付' &&
                (orderDetailOption.orderStatus !== '已取消' ||
                  orderDetailOption.orderStatus !== '已退款') &&
                orderDetailOption.payWay === '收钱吧'
            "
          >
            手动查询
          </el-button>
        </div>
      </div>
    </div>
    <div
      class="contentView"
      style="height: 669px; margin-top: 20px;"
      v-loading="orderInfoLoading"
    >
      <div class="clothesInfo-contentView">
        <div style="display: flex;">
          <div class="infoView">
            <div>
              <label class="label-leftText">单号：</label>
              <label>{{ orderDetailOption.orderNumber }}</label>
            </div>
            <div>
              <label class="label-leftText">消费日期：</label>
              <label>{{ orderDetailOption.orderTime }}</label>
            </div>
            <div>
              <label class="label-leftText">订单状态：</label>
              <label>{{ orderDetailOption.orderStatus }}</label>
            </div>
            <div>
              <label class="label-leftText">支付状态：</label>
              <label style="color: red;">
                {{ orderDetailOption.payStatus }}
              </label>
            </div>
            <div>
              <label class="label-leftText">所属门店：</label>
              <label>{{ orderDetailOption.storeName }}</label>
            </div>
          </div>
          <div class="infoView">
            <div>
              <label class="label-leftText">用户姓名：</label>
              <label>{{ orderDetailOption.orderRealName }}</label>
            </div>
            <div>
              <label class="label-leftText">手机号：</label>
              <label>{{ orderDetailOption.orderUserPhone }}</label>
            </div>
            <div>
              <label class="label-leftText">地址：</label>
              <label>{{ orderDetailOption.userAddress }}</label>
            </div>
            <div>
              <label class="label-leftText">操作员：</label>
              <label>{{ orderDetailOption.storeUserName }}</label>
            </div>
          </div>
        </div>
        <div class="contentView">
          <div class="tView">
            <h4 style="margin: 0;">
              衣物详情(件数: {{ orderDetailOption.clothesList.length }}):
            </h4>
            <span>
              <el-checkbox v-model="checkAll" @change="handleCheckAllChange">
                全选</el-checkbox
              >
            </span>
            <el-button type="primary" @click="clothesPick(orderDetailOption)">
              取衣
            </el-button>
          </div>
          <div class="cashContentView">
            <div class="headerView flexView" style="color: #999;">
              <label>选项</label>
              <label>衣物号</label>
              <label>店长备注</label>
              <label>状态</label>
              <label>衣物名称</label>
              <label>颜色</label>
              <label>原价/折后价</label>
              <label>操作</label>
            </div>
            <div
              class="flexView"
              v-for="(item, index) in orderDetailOption.clothesList"
              :key="index"
              :style="{
                'text-decoration':
                  item.clothesStatus === '已取衣' ||
                  item.clothesStatus === '已退衣'
                    ? 'line-through'
                    : '',
              }"
            >
              <label>
                <el-checkbox
                  :value="
                    (checkedClothesList.indexOf(item.id) === -1 ? '-' : '') +
                      item.id
                  "
                  :true-label="item.id"
                  :false-label="'-' + item.id"
                  @change="changeCheck"
                ></el-checkbox>
              </label>
              <label>
                <el-tag
                  size="mini"
                  type="info"
                  v-if="item.clothesStatus === '已退洗'"
                >
                  退
                </el-tag>
                <el-tag size="mini" v-if="item.accessoryNumber">附</el-tag>
                {{ item.outClothesNum }}
              </label>
              <label class="shopowner" :title="item.remark">
                {{ item.remark }}
              </label>
              <label>{{ item.clothesStatus }}</label>
              <label>{{ item.clothesName }}</label>
              <label>{{ item.colorName }}</label>
              <label v-if="!item.accessoryNumber">
                {{ item.originalPrice }}元/{{ item.currentPrice }}元
              </label>
              <label v-else>0元</label>
              <!-- <label v-else></label> -->
              <label>
                <el-button
                  type="primary"
                  size="mini"
                  @click="showDialogDetail(item)"
                >
                  详情
                </el-button>
              </label>
            </div>
          </div>
        </div>
        <div class="payInfoView">
          <div class="tView">
            金额
          </div>
          <div>
            <div class="amtView">
              <div>
                <label class="label-leftText">衣物原价：</label>
                <label class="label-amt">
                  ¥ {{ orderDetailOption.productOriginalPrice }}
                </label>
              </div>
              <div>
                <label class="label-leftText">折后总价：</label>
                <label class="label-amt">
                  ¥ {{ orderDetailOption.actuallyPaidPrice }}
                </label>
              </div>
              <div>
                <label class="label-leftText">订单总额：</label>
                <label class="label-amt">
                  ¥ {{ orderDetailOption.actuallyPaidPrice }}
                </label>
              </div>
            </div>
            <div class="payTypeView">
              <div class="payTypeItem">
                <label class="label-leftText">支付方式：</label>
                <label class="label-name">{{ orderDetailOption.payWay }}</label>
              </div>
            </div>
            <div class="remarkView">
              <div>
                <label class="label-leftText">顾客备注信息：</label>
                <label>{{ orderDetailOption.orderRemark }}</label>
              </div>
              <!-- <div>
                <label class="label-leftText">店长备注信息：</label>
                <label>{{ orderDetailOption.storeManagerRemark }}</label>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--衣物详情-->
    <pop-view
      id="order-detailViewPopView"
      :show="detailView"
      width="600px"
      title="衣物详情"
      @cancel="detailView = false"
    >
      <div class="menuView">
        <button
          style="background: transparent;"
          :class="{ select: currentTab === item.val }"
          v-for="(item, index) in tabs"
          @click="handleDetailTab(item.val)"
          :key="index"
        >
          {{ item.title }}
        </button>
      </div>
      <div
        class="contentView"
        style="margin-top: 20px;"
        v-if="currentTab === 'info'"
      >
        <div style="display: flex;">
          <div class="infoView">
            <div>
              <label class="label-leftText">衣物名称：</label>
              <label>{{ clothes.clothesName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物号：</label>
              <label>{{ clothes.clothesNum }}</label>
            </div>
            <div>
              <label class="label-leftText">洗涤方式：</label>
              <label>{{ clothes.clothesServiceName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物状态：</label>
              <label>{{ clothes.clothesStatus }}</label>
            </div>
          </div>
          <div class="infoView">
            <div>
              <label class="label-leftText">颜色：</label>
              <label>{{ clothes.colorName }}</label>
            </div>
            <div>
              <label class="label-leftText">品牌：</label>
              <label>{{ clothes.brandName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物价格：</label>
              <label>¥{{ clothes.currentPrice }}</label>
            </div>
          </div>
        </div>
        <div class="infoView">
          <div>
            <label class="label-leftText">预计取衣时间：</label>
            <label>{{ clothes.estimatedWashTime }}</label>
          </div>
          <div>
            <label class="label-leftText">洗后效果：</label>
            <label>{{ clothes.effects }}</label>
          </div>
          <div>
            <label class="label-leftText">瑕疵：</label>
            <label>{{ clothes.blemishes }}</label>
          </div>
          <div>
            <label class="label-leftText">备注：</label>
            <label>{{ clothes.remark }}</label>
          </div>
        </div>
        <div
          style="
            display: flex;
            margin-top: 20px;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: space-between;
          "
        >
          <el-image
            style="margin-left: 10px; width: 22%; margin-bottom: 10px;"
            v-if="clothes.photos !== null"
            v-for="url in clothes.photos"
            :key="url"
            :src="url"
            :preview-src-list="clothes.photos"
            lazy
          ></el-image>
        </div>
      </div>
      <div
        class="contentView"
        style="margin-top: 20px;"
        v-if="currentTab === 'status'"
      >
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in clothesStatusList"
            :key="index"
            color="#0bbd87"
            :timestamp="activity.operateTime"
          >
            {{ activity.description }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </pop-view>

    <!--支付页面-->
    <transition name="payFullscreen">
      <div
        class="cardCardCheckoutView"
        v-if="payView"
        v-loading="payBtnLoading"
        :element-loading-text="payBtnLoadingVal"
      >
        <div class="leftView" style="margin-right: 480px;">
          <div class="topView">
            <label>支付</label>
            <button class="btn-back" @click="handleCloseCardPay"></button>
          </div>
          <div class="contentView listView" style="height: 620px;">
            <div class="orderInfoView">
              <div class="timeView">下单时间：2020-08-27 14:57</div>
              <!--                                <div class="cardView">-->
              <!--                                    <label>卡片名称：{{this.applyCardForm.cardName}}</label>-->
              <!--                                </div>-->
              <div class="toPayView">
                <div class="amtItem subItem">
                  <label class="label-name">
                    <span>
                      原价:￥{{ clothesOrderPrice.orderOriginalPrice }}
                    </span>
                  </label>
                  <label class="label-name">
                    <span>
                      优惠金额:￥{{ clothesOrderPrice.actuallyPaidPrice }}
                    </span>
                  </label>
                </div>
                <div class="amtItem subItem">
                  <label class="label-name">
                    <span>
                      折后总价: ￥{{ clothesOrderPrice.actuallyPaidPrice }}
                    </span>
                  </label>
                  <label class="label-name">
                    <span style="color: red;">
                      实付金额:￥{{ clothesOrderPrice.actuallyPaidPrice }}
                    </span>
                  </label>
                  <label class="label-name" v-if="payType === 2">
                    <span style="color: red;">
                      待补款:￥{{ clothesOrderPrice.waitPayPrice }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="paymentView">
              <div class="tView">支付方式</div>
              <div class="listView">
                <div
                  class="paymentItem listItem"
                  v-for="(item, index) in pay_ways"
                  :key="index"
                  @click="handlePayWay(item)"
                >
                  <div class="iconView">
                    <!--                                        <img :src="item.ico">-->
                    <label class="label-icon">{{ item.ico }}</label>
                  </div>
                  <div class="textView overflowText" v-if="index === 0">
                    {{ item.name }}({{
                      userAccount.balance == null ? 0 : userAccount.balance
                    }})
                  </div>
                  <div class="textView overflowText" v-else>
                    {{ item.name }}
                  </div>
                  <div class="selectView" v-if="payWay === item.label">
                    <img
                      src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_Black.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btnView">
            <div class="btn-settle" @click="payBtn">结账</div>
          </div>
        </div>
        <div class="rightView" style="width: 480px;">
          <div class="defImgView">
            <img
              src="https://static.bokao2o.com/wisdomDesk/images/Def_Bc_OrderCheckout_Right.png"
            />
            <p>{{ orderCheckoutVal }}</p>
          </div>
          <transition name="paidFullscreen">
            <div
              id="qrCodePayPopView"
              class="popView"
              style="width: 480px;"
              v-if="payWayView"
              v-loading="qrCodePayView"
              :element-loading-text="qrCodePayViewName"
            >
              <div class="popView-bcView"></div>
              <div
                class="popView-contentView"
                style="width: 450px; top: 0; right: 0; overflow: auto;"
              >
                <div class="qrCodePayView" style="height: 794px;">
                  <div class="contentView" style="height: 513px;">
                    <div class="amtView">
                      ¥
                      <span>{{ payPrice }}</span>
                    </div>
                    <div class="imgView">
                      <img src="https://www.shouqianba.com/img/logo.svg" />
                      <!--                                            <label class="label-icon">银</label>-->
                    </div>
                    <div class="qrCodeInputView">
                      <el-input
                        class="input-scanPayCode"
                        placeholder="请扫描或输入付款码"
                        autocomplete="off"
                        ref="pay"
                        v-model="payVal"
                        type="number"
                        autofocus="autofocus"
                      ></el-input>
                      <button @click="payBtn">收款</button>
                    </div>
                    <div class="tipView">
                      请使用支付宝/微信扫码支付
                      <p>支付过程中，请不要关闭该二维码！</p>
                    </div>
                  </div>
                </div>
                <button class="btn-close" @click="payWayView = false"></button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  getClothesOrderDetails,
  getOrderPrice,
  payOrder,
  payOrderQuery,
} from "@/api/clothes/order";
import { clothesPick } from "@/api/clothes/clothes";
import PopView from "@/components/PopView";
import { getUserAccount } from "@/api/member/user";

export default {
  name: "clothesDetailView",
  components: {
    PopView,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      clothesNumber: "",
      orderDetailOption: {
        clothesList: [],
      },
      orderInfoLoading: false,
      checkedClothesList: [],
      checkAll: false,
      detailView: false,
      clothes: {},
      clothesStatusList: [],
      tabs: [
        { val: "info", title: "信息" },
        { val: "status", title: "状态" },
      ],
      orderNumber: "",
      currentTab: "info",
      // 支付类型
      payType: 1,
      // 用户余额
      userAccount: {},
      // 衣物订单价格
      clothesOrderPrice: {},
      // 支付
      payView: false,
      // 支付方式
      pay_ways: [
        {
          name: "余额",
          ico: "余",
          label: 3,
        },
        {
          name: "现金",
          ico: "现",
          label: 4,
        },
        {
          name: "收钱吧",
          ico: "收",
          label: 6,
        },
      ],
      payWay: "",
      payWayView: false,
      // 付款码付款
      qrCodePayView: false,
      // 付款码提示
      qrCodePayViewName: "收款中...",
      payBtnLoading: false,
      payBtnLoadingVal: "收衣中...",
      orderCheckoutVal: "请选择结账方式",
      myInterval: null,
      shouInterval: null,
      payVal: "",
    };
  },
  methods: {
    // getFocus(){
    //  this.$nextTick(()=>{
    //   this.$refs.clothesRefs.focus();
    //  })
    // },
    // 获取订单详情
    getClothesOrderDetails(orderNumber) {
      this.orderNumber = orderNumber;
      this.orderInfoLoading = true;
      // 初始化
      this.checkedClothesList = [];
      getClothesOrderDetails(orderNumber).then((response) => {
        this.orderInfoLoading = false;
        this.orderDetailOption = response.data.data;
      });
    },
    closeVisible() {
      this.checkAll = false;
      // this.$parent.handleClose();
      this.$emit("closeDel", false);
    },
    handleCheckAllChange(val) {
      console.log("这尼玛又是个啥", val);
      this.checkedClothesList = val
        ? this.orderDetailOption.clothesList.map((i) => i.id)
        : [];
      // this.isIndeterminate = false;
    },
    changeCheck(val) {
      console.log("这是个啥", val);
      if (val.indexOf("-") === -1) {
        this.checkedClothesList.push(val);
      } else {
        this.checkedClothesList = this.checkedClothesList.filter(
          (i) => i !== val.slice(1)
        );
      }
      let checkedCount = this.orderDetailOption.clothesList.length;
      this.checkAll = checkedCount === this.checkedClothesList.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.orderDetailOption.clothesList.length;
    },
    // 自取
    clothesPick(orderDetailOption) {
      console.log(orderDetailOption);
      let checkedClothesList = this.checkedClothesList.filter(function(s) {
        return s && s !== "";
      });
      let clothesArr = orderDetailOption.clothesList.find((item) => {
        return checkedClothesList.find((item1) => {
          return item.id == item1;
        });
      });
      if (clothesArr) {
        this.$confirm("", {
          title: "取衣提示",
          // message:this.$createElement("div",{style:{marginLeft:'15px'}},[
          //  this.$createElement('p',{},`顾客备注信息: ${orderDetailOption.orderRemark ? orderDetailOption.orderRemark : ''}`),
          //  this.$createElement('p',{},`店长备注信息: ${clothesArr.remark ? clothesArr.remark : ''}`)
          // ])
          message: "是否确定取衣?",
          confirmButtonText: "确定取衣",
          cancelButtonText: "取消取衣",
          type: "warning",
        })
          .then(() => {
            let data = {
              orderNumber: orderDetailOption.orderNumber,
              // outClothesNum: this.clothesNumber
              clothesNumbers: checkedClothesList,
            };
            console.log(data);
            clothesPick(data).then((response) => {
              console.log(response);
              if (response.data.code === 0) {
                if (response.data.data === 1) {
                  this.$message.success(response.data.msg);
                  this.getClothesOrderDetails(this.orderNumber);
                } else {
                  this.$message.success("取衣成功!");
                  // this.clothesNumber = '';
                  // this.getFocus();
                  this.getClothesOrderDetails(this.orderNumber);
                }
              } else {
                this.$message.error(response.data.msg);
                // this.clothesNumber = '';
                // this.getFocus();
              }
            });
          })
          .catch(() => {
            this.$message("取消取衣!");
            // this.clothesNumber = '';
            // this.getFocus();
          });
      } else {
        this.$message.warning("该订单下没有此衣物！");
        // this.clothesNumber = '';
        // this.getFocus();
      }
    },
    showDialogDetail(item) {
      let _this = this;
      _this.clothesStatusList = [];
      _this.clothes = item;
      console.log(_this.clothes.clothesStatusList);
      _this.clothes.clothesStatusList.forEach(function(item, index) {
        if (item.active) {
          _this.clothesStatusList.push(item);
        }
      });
      this.detailView = true;
    },
    handleDetailTab(val) {
      this.currentTab = val;
    },
    // 查询订单金额
    handlePayModal() {
      if (this.orderDetailOption.orderStatus === "已取消") {
        this.$message.error("订单已取消，无法支付");
        return;
      }
      // 打开支付弹框
      this.payView = true;
      // 查询订单
      getOrderPrice(this.orderNumber).then((res) => {
        this.clothesOrderPrice = res.data.data;
        this.payPrice = res.data.data.actuallyPaidPrice;
        getUserAccount(res.data.data.userId).then((response) => {
          console.log(response);
          this.userAccount = response.data.data;
        });
      });
    },
    // 关闭支付页面
    handleCloseCardPay() {
      this.payView = false;
      this.payWay = "";
      this.payWayView = false;
    },
    // 处理点击支付
    handlePayWay(item) {
      this.payWayView = false;
      this.payWay = item.label;
      this.clothes.payWay = item.label;
      if (item.label === 6) {
        this.payWayView = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      } else {
        this.orderCheckoutVal = "请使用" + item.name + "收款: " + this.payPrice;
      }
    },
    // 支付按钮
    payBtn() {
      if (!this.clothes.payWay) {
        this.$message.error("请选择支付方式!");
        return false;
      }
      // 如果选择了余额或者洗后付或现金 不进行弹框
      if (
        this.clothes.payWay === 1 ||
        this.clothes.payWay === 2 ||
        this.clothes.payWay === 6
      ) {
        // if (this.choosePaywayIndex !== 0) this.payWayView = true
        this.qrCodePayView = true;
        this.payOrder();
      } else {
        // 余额
        if (this.clothes.payWay === 3) {
          if (this.payType === 2) {
            // 付款金额与余额比较
            if (
              this.userAccount.balance >= this.clothesOrderPrice.waitPayPrice
            ) {
              console.log("差额补余额支付");
              if (this.payType === 2) {
                this.payVal = "DIFF";
              }
              this.payBtnLoading = true;
              this.payOrder();
            } else {
              this.$message.error("余额不足无法支付");
            }
          } else {
            // 付款金额与余额比较
            if (
              this.userAccount.balance >=
              this.clothesOrderPrice.actuallyPaidPrice
            ) {
              console.log("余额支付");
              this.payBtnLoading = true;
              this.payOrder();
            } else {
              this.$message.error("余额不足无法支付");
            }
          }
        }
        if (
          this.clothes.payWay === 4 ||
          this.clothes.payWay === 5 ||
          this.clothes.payWay === 7
        ) {
          if (this.payType === 2) {
            this.payVal = "DIFF";
          }
          this.payBtnLoading = true;
          this.payOrder();
        }
      }
    },
    // 支付订单
    payOrder() {
      let payWay = this.clothes.payWay;
      let orderNumber = this.orderNumber;
      let authCode = this.payVal;
      let data = {
        payWay,
        orderNumber,
        authCode,
      };
      payOrder(data)
        .then((response) => {
          console.log(response);
          if (response.data.code === 0) {
            // 只有收钱吧有回调
            if (this.clothes.payWay === 6) {
              this.outTradeNo = response.data.data.outTradeNo;
              this.myInterval = setInterval(() => {
                this.queryPayResult(this.outTradeNo); //调用接口的方法
              }, 3000);
            } else {
              this.commonPaySuccess();
            }
          } else {
            if (this.clothes.payWay === 6) {
              clearInterval(this.shouInterval);
              // 需要用户支付
              if (response.data.data.errCode === "USERPAYING") {
                console.log(response);
                this.outTradeNo = response.data.data.outTradeNo;
                this.myInterval = setInterval(() => {
                  this.queryPayResult(this.outTradeNo); //调用接口的方法
                }, 3000);
              } else {
                console.log(response);
                this.$message.error(response.data.data.msg);
                this.payBtnLoading = false;
                this.closePayModal();
              }
            } else {
              this.$message.error(response.data.data.msg);
            }
          }
        })
        .catch((reason) => {
          // 要进行跳转到当前未支付的订单
          console.log(reason);
          this.closePayModal();
        });
      if (this.clothes.payWay === 6) {
        this.payBtnLoadingVal = "等待用户输入密码!";
      }
    },
    // 查询支付结果
    queryPayResult(outTradeNo) {
      if (this.clothes.payWay === 6) {
        clearInterval(this.shouInterval);
      }
      const params = new URLSearchParams();
      params.append("payWay", this.clothes.payWay);
      params.append("outTradeNo", outTradeNo);
      params.append("orderNumber", this.orderNumber);
      payOrderQuery(params).then((response) => {
        console.log(response);
        // 微信
        if (response.data.data.success) {
          if (response.data.data.tradeState === "SUCCESS") {
            this.commonPaySuccess();
          } else if (response.data.data.tradeState === "PAYERROR") {
            this.payBtnLoading = false;
            this.$message.error("用户支付失败,请重新扫码支付");
            this.closePayModal();
          } else {
            this.payBtnLoadingVal = "等待用户输入密码!";
          }
        } else {
          this.payBtnLoading = false;
          this.$message.error("用户支付失败,请重新扫码支付");
          this.closePayModal();
        }
      });
    },
    // 公共的
    commonPaySuccess() {
      this.payBtnLoading = false;
      this.payView = false;
      this.payWayView = false;
      this.payWay = "";
      this.$message.success("支付成功");
      clearInterval(this.myInterval);
      this.getClothesOrderDetails(this.orderNumber);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.shopowner {
  flex: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clothesDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;
  overflow-y: auto;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 76px);
    height: 90px;
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      // padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        // display: flex;
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: "";
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }
    }
  }
}

.clothesInfo-contentView {
  > div {
    > .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 80px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }

  > .contentView {
    > div {
      padding-left: 20px;
      padding-right: 20px;
      line-height: 20px;
    }

    > .tView {
      position: relative;
      background: #f8f8f8;
      padding: 15px 20px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium;
      color: #333;
      overflow: hidden;

      > button {
        position: absolute;
        top: 8px;
        right: 20px;
        // line-height: 50px;
        // height: 50px;
        // background: transparent;
        font-size: 14px;
        // padding: 0;
        // color: #3370ff;
      }
    }

    .cashContentView {
      max-height: 300px;
      padding-left: 45px;
      overflow: auto;

      > .headerView {
        color: #999;
      }
    }

    > .cashContentView .flexView {
      display: flex;
      text-align: center;
      font-size: 14px;
      color: #333;
      line-height: 20px;
      padding: 6px 0;

      > label {
        flex: 1;
        cursor: pointer;
      }

      > label:nth-child(1) {
        text-align: left;
        flex: 0.5;
      }

      > label:nth-child(2) {
        flex: 1;
      }
    }
  }

  > .payInfoView {
    padding-bottom: 25px;

    > div {
      display: flex;
      color: #333;
      line-height: 20px;
      font-size: 14px;
      padding: 6px 20px 6px 20px;
    }

    > div .label-leftText {
      width: 80px;
      min-width: 80px;
      color: #999;
    }

    > div .label-amt {
      color: #ff5e56;
      font-family: DINAlternate-Bold;
    }

    > div {
      > div {
        margin-top: 10px;
      }

      > div.payTypeView {
        margin-left: 20px;
      }

      > div.remarkView {
        margin-left: 20px;
      }
    }

    > .tView {
      position: relative;
      background: #f8f8f8;
      padding: 15px 20px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium;
      color: #333;
      overflow: hidden;

      > button {
        position: absolute;
        top: 0;
        right: 20px;
        line-height: 50px;
        height: 50px;
        background: transparent;
        font-size: 14px;
        padding: 0;
        color: #3370ff;
      }
    }
  }
}

#order-detailViewPopView > .popView-contentView {
  > .menuView {
    position: absolute;
    width: 88px;
    display: flex;
    top: 45px;
    left: 0;
    right: 0;
    margin: auto;

    > button {
      font-size: 16px;
      color: #333;
      flex: 1;
      line-height: 30px;
      height: 30px;
      padding: 0;
    }

    > button.select {
      position: relative;
      color: #3370ff;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
    }

    > button.select:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0px;
      position: absolute;
      width: 24px;
      height: 2px;
      background: #3370ff;
      margin: auto;
      border-radius: 1px;
    }
  }

  > .contentView {
    .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 99px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }
}

.cardCardCheckoutView {
  position: fixed;
  display: flex;
  top: 0;
  /*left: 0;*/
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  z-index: 100;

  > .leftView {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;

    > .topView {
      position: relative;
      background: #fff;
      padding: 25px 0 15px 0;
      text-align: center;

      > label {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        line-height: 40px;
      }

      > .btn-back {
        position: absolute;
        top: 25px;
        left: 15px;
        width: 40px;
        height: 40px;
        background-image: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png);
        background-position: left center;
        background-size: 28px;
        background-repeat: no-repeat;
      }
    }

    > .contentView {
      overflow-x: hidden;
      overflow-y: auto;

      > .orderInfoView .subItem {
        display: flex;
        padding: 12px 25px 12px 20px;
        line-height: 20px;

        > label {
          flex: 1;
          height: 20px;
        }

        > .label-name {
          flex: 2;
          color: #666;

          > span {
            font-family: PingFangSC-Semibold;
            font-size: 15px;
            color: #333;
            margin-right: 15px;
          }
        }

        > .label-amt {
          text-align: right;
          font-size: 15px;
          color: #333;
          font-family: DINAlternate-Bold;
        }
      }

      > .orderInfoView {
        padding: 0 30px 15px 30px;

        > .timeView {
          line-height: 30px;
          font-size: 14px;
          color: #666;
        }

        > .cardView {
          line-height: 32px;
          color: #333;
          font-size: 14px;

          > label {
            margin-right: 35px;
          }

          > label:last-child {
            margin-right: 0;
          }
        }

        > .toPayView {
          padding: 5px 0;
          margin-top: 15px;
          background: #f8f8f8;
          border-radius: 6px;
          overflow: hidden;
        }
      }

      > .paymentView {
        padding: 0 24px 0 24px;

        > .tView {
          margin: 0 6px;
          line-height: 40px;
          font-size: 15px;
          font-family: PingFangSC-Semibold;
          color: #333;
          border-top: 0.5px solid #ddd;
        }

        > .listView {
          overflow: hidden;

          > .paymentItem {
            position: relative;
            float: left;
            width: 87px;
            height: 87px;
            border-radius: 6px;
            background: #f8f8f8;
            padding: 10px 0;
            text-align: center;
            margin: 0 6px 10px 6px;
            overflow: hidden;

            > .iconView {
              height: 40px;

              > img {
                width: 40px;
                height: 40px;
              }

              > .label-icon {
                display: inline-block;
                border-radius: 6px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Medium;
                color: #fff;
                text-align: center;
                background: rgba(66, 153, 255, 0.7);
              }
            }

            > .textView {
              margin-top: 8px;
              line-height: 20px;
              font-size: 14px;
              color: #333;
            }

            > .selectView {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(40, 40, 45, 0.4);
              text-align: center;
              border-radius: 6px;

              > img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }

    > .btnView {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 20px 0;
      text-align: center;
      background: #fff;

      > .btn-settle {
        display: inline-block;
        width: 260px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        background: #3370ff;
        border-radius: 6px;
      }
    }
  }

  > .rightView {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff
      url(https://static.bokao2o.com/wisdomDesk/images/Def_OrderCheckout_RightBorder.png)
      left center / 27px no-repeat;
    z-index: 10;

    > .defImgView {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 180px;

      > img {
        width: 192px;
        height: 150px;
      }

      > p {
        margin-top: 10px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}

//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}

#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #ff5e56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          /* width: 320px; */
          /* line-height: 40px; */
          /* height: 40px; */
        }

        > button {
          width: 72px;
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          font-family: PingFangSC-Semibold;
          color: #fff;
          margin-left: 15px;
          background: #3370ff;
          border-radius: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}

button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
